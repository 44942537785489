<template>
  <div class="position-relative w-100">
    <div class="d-flex align-items-stretch search-final">
      <img class="" v-if="res" :src="courseThumbnail(res)" />
      <b-form-input
        v-model="searchedText"
        @blur="showSearchResults = false"
        class="a-btn pl-2 search w-100"
        type="search"
        autocomplete="off"
        placeholder="Začnite písať názov kurzu"
        debounce="300"
      />
    </div>

    <div v-if="showSearchResults && searchResults" class="search-results">
      <Loadable
        :items="courses"
        :class="{ 'search-item': !courses || (courses && !courses.length) }"
      />
      <div v-if="courses">
        <div v-if="searchResults.length">
          <template v-for="(res, i) of searchResults">
            <div :key="i" @mousedown="handleSelect(res)" class="search-result">
              <img :src="courseThumbnail(res)" />
              <span>{{ res.attributes.name }}</span>
            </div>
          </template>
        </div>
        <div v-else-if="courses.length && searchResults.length === 0">
          <div
            v-if="searchedText.length >= minSearchLength"
            class="search-item"
          >
            Nič sme nenašli
          </div>
          <div v-else class="search-item">
            Zadajte aspoň {{ minSearchLength }} znaky
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "/utils/axios";
import { getCourseThumbnailUrl, injectCourseMetaFields } from "/utils/helpers";
import * as Sentry from "@sentry/vue";

const api = {
  courses: (searchString) => {
    return axios.getAuth("courses", {
      params: {
        populate: ["thumbnail", "quiz.form"],
        pagination: {
          limit: 6,
        },
        filters: {
          searchable: true,
          quiz: {
            id: {
              $ne: null,
            },
          },
          $or: [
            {
              name: {
                $containsi: searchString,
              },
            },
            {
              tagsString: {
                $containsi: searchString,
              },
            },
            {
              description: {
                $containsi: searchString,
              },
            },
          ],
        },
      },
    });
  },
};

export default {
  data() {
    return {
      courses: null,
      searchConfig: { minLength: 3 },
      searchedText: "",
      showSearchResults: false,
      searchResults: [],
      searchResultsMap: new Set([]),
      selectedCourse: false,
      res: null,
    };
  },

  components: {
    Loadable: () => import("/components/Loadable.vue"),
  },

  computed: {
    minSearchLength() {
      return this.searchConfig.minLength || 0;
    },
  },

  watch: {
    searchedText: async function (newValue) {
      this.searchResultsMap = new Set([]);
      this.$emit("inputValChange", newValue);

      if (this.selectedCourse) {
        this.selectedCourse = false;
        return;
      }

      this.res = null;

      if (!newValue || newValue.length < this.minSearchLength) {
        this.searchResults = [];
        this.showSearchResults = true;
        return;
      }

      try {
        const courses = await api.courses(newValue);
        this.courses = courses.data;

        this.courses.forEach((course) => {
          injectCourseMetaFields(course, "course");
        });

        this.searchResults = this.courses;
        this.showSearchResults = true;
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }

      if (this.res && this.searchedText === this.res.attributes.name) {
        return;
      }

      this.res = null;
    },
  },

  methods: {
    handleSelect(item) {
      this.$emit("selected", item);

      this.res = item;
      this.searchedText = item.attributes.name;
      this.selectedCourse = true;
    },
    courseThumbnail(item) {
      return getCourseThumbnailUrl(item);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  height: auto;
  border: 0;
  border-radius: 0;
  // border-bottom: 1px solid var(--a-color-blue-light);
  // padding-left: 2.75rem;

  &::placeholder {
    color: rgba(156, 161, 174, 0.4) !important;
    font-size: 18px;
    line-height: 27px;
  }

  &:focus,
  &:active {
    background: var(--a-color-white);
    color: var(--a-color-black);
    box-shadow: none;
    // border-bottom: 1px solid var(--a-color-blue-light);
  }
}

.search-final {
  border-bottom: 1px solid var(--a-color-blue-light);
  height: 48px;
  margin-right: 20px;
}

.search-results {
  position: absolute;
  padding-left: 16px;
  padding-right: 20px;
  background: var(--a-color-white);
  border: 1px solid var(--a-color-blue-light);
  border-radius: 10px;
  box-shadow: 0px 4px 40px var(--a-color-blue-light);
  top: calc(100% + 10px);
  width: 100%;
  cursor: pointer;
}

.search-item {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px;
  margin: 12px 0;
  font-size: 14px;
}

.search-result {
  font-weight: 700;
  min-height: 80px;
  @extend .search-item;

  &:first-of-type {
    margin-top: 6px;
  }

  &:last-of-type {
    margin-bottom: 6px;
  }

  &:not(:last-of-type) {
    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      bottom: -6px;
      left: 0px;
      background: var(--a-color-blue-light);
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    background-color: var(--a-color-blue-lightest);
    color: var(--a-color-black);
  }

  img {
    height: 48px;
    margin-right: 20px;
  }
}
</style>
